/*eslint-disable*/
import React, { useEffect } from "react";
// react components for routing our app without refresh
import { Link, NavLink, useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Menu, MenuItem, Button } from '@material-ui/core';
import { toastAlert } from "../../helper/toastAlert";
// @material-ui/ Material Icons
import TelegramIcon from '@material-ui/icons/Telegram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';

import { connection } from "../../helper/connection";
import { ContractToSolid } from "../../helper/ContractToSolid";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import config from "../../config/config";
const useStyles = makeStyles(styles);

const networkVersion = config.networkVersion;
const networkName = config.networkName;

export default function HeaderLinks(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  try {
    window.ethereum.on('accountsChanged', async function (account) {
      setTimeout(function () {
        //window.location.reload()
      }, 300);
    });
  } catch (err) {

  }

  try {
    window.ethereum.on('networkChanged', function (networkId) {
      console.log('networkIdnetworkId', networkId)
      checkNetwork();
    })
  } catch (err) {

  }
  useEffect(() => {
    setTimeout(function () {
      checkUser();
      checkNetwork();
    }, 700);
  }, []);

  async function checkUser() {

    try {
      var WEB3 = await connection();
      var currAddr = WEB3.address;
      var network = WEB3.network;
      var Contract = await ContractToSolid();
      var userData = await Contract.methods.users(currAddr).call();
      var id = parseInt(userData.id);
      if (id <= 0 || networkVersion != network) {
        history.push("/");
      }
    } catch (err) {

    }


  }

  async function checkNetwork() {

    var WEB3 = await connection();
    var currAddr = WEB3.address;
    var network = WEB3.network;
    if (networkVersion == network) {
      $('#loader_modal').css('display', 'none');
      $('#loader_modal').removeClass('show');
    } else {
      $('#loader_modal').css('display', 'block');
      $('#loader_modal').addClass('show');
    }

  }


  async function Logout() {
    localStorage.setItem('nhfgytuvc', 'no');
    toastAlert('success', "Successfully logout", 'logout');
    history.push("/");
  }

  const classes = useStyles();
  return (
    <div className="dash_menu">

      <div class="modal fade primary_modal" id="loader_modal" tabindex="-1" role="dialog" aria-labelledby="loader_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <p style={{ "textAlign": "center", "fontSize": "20px" }}>Wrong Network</p>
            </div>
            <div class="modal-body text-center">
              <p className="mb-0" style={{ "fontSize": "16" }}>Please login into {networkName}</p>
              <img src={require("../../assets/images/loader.gif")} alt="Loader" className="img-fluid" style={{ width: "200px" }} />
            </div>
          </div>
        </div>
      </div>

      <List className={classes.list + " main_navbar"}>
        <ListItem className={classes.listItem + " logout_btn"}>
          <Link to="/dashboard" color="transparent" className="nav-link">Dashboard</Link>
        </ListItem>
        <ListItem className={classes.listItem + " logout_btn"}>
          <Link to="/Partners" color="transparent" className="nav-link">Partners</Link>
        </ListItem>
        <ListItem className={classes.listItem + " logout_btn"}>
          <Link to="/Statistics" color="transparent" className="nav-link">Statistics</Link>
        </ListItem>
        <ListItem className={classes.listItem + " logout_btn"}>
          <ul className="topSocialIcons">
            <li><a href="https://www.facebook.com" target="_blank"><FacebookIcon /></a></li>
            <li><a href="https://www.instagram.com" target="_blank"><InstagramIcon /></a></li>
            <li><a href="https://t.me" target="_blank"><TelegramIcon /></a></li>
          </ul>
        </ListItem>
        <ListItem className={classes.listItem + " logout_btn"}>
          <Button onClick={() => { Logout() }} className="topLinkButton"><span className="innerButtonBg">Logout</span></Button>
        </ListItem>
      </List>
    </div>
  );
}
