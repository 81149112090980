import React, { useEffect, useState } from "react";
import axios from 'axios';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import styled from "styled-components";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import config from "../config/config";
import { currencyRate } from "../helper/currencyRate"

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const baseUrl = config.baseUrl;

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">x</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    color: #010017;
    font-size: 40px;
    background-color: #0062bb;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > .minus {
    display: none;
    color: #010017;
    font-size: 20px;
  }
  .Mui-expanded & > .minus {
    color: #010017;
    font-size: 20px;
    background-color: #0062bb;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 25px;
    font-weight: 700;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('panel1');
  const [earnerList, setearnerList] = React.useState([]);
  const [rate, setrate] = React.useState(0);
  const [detail, setdetail] = React.useState({totalmember:0, todaymember:0, totalearn:0, todayearn:0});

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getRate();
  }, []);

  async function getRate(){

    var getVal = await currencyRate();
    setrate(getVal);
    earningDetails(getVal);

    setTimeout(function(){
      topEarners();
    },200);
  }

  async function topEarners(){

    var response = await axios.get(baseUrl + 'top-earners', {});
    var list = (response && response.data && response.data.result)?response.data.result:[]
    setearnerList(list)
  
  }

  async function earningDetails(value){
    var response = await axios.get(baseUrl + 'earning-details', {});
    if(response && response.data && response.data.result){
      var {totalmember, todaymember, totalearn, todayearn} = response.data.result;
      var usdrate = parseFloat(value);
      var today = parseFloat(todayearn);
      var usdRateVal = usdrate*today;
      setdetail({totalmember, todaymember, totalearn, todayearn:usdRateVal})
    }
  }

  const { ...rest } = props;

  return (
    <div>
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={(localStorage.getItem('nhfgytuvc')=="yes")?<HeaderLinksAfterlogin />:<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <section className="heroSection">
        <div className="container">
            <div className="heroCard">
              <article>
                  <h3>Unlimited Earning With Decentralized Technology</h3>
                  <h1>ToSolid Platform</h1>
                  <p>Connect people from all over the world in a Revolutionary way based on Decentralized Smart contracts</p>
              </article>
              <div className="join__btn">
                  <Button><Link to="/register">Join now</Link></Button>
              </div>
            </div>
          </div>
        </section>
        <section className="homeFeatures" name="features">
          <div className="container">
            <div className="titleDoubleColor">
                <h2>ToSolid <span>Features</span></h2>
            </div>
            <div className="homeFeaturesCard">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6} lg={3}>
                    <div className="hfCard">
                        <div className="image__div">
                          <img src={require("../assets/images/sec2_i1_08.png")} alt="" className="img-fluid image__inside" />
                        </div>
                        
                        <h2 className="h1__feature">100% Decentralized</h2>
                        <p className="p__feature">Automated contracts are used to create decentralized marketing, which provides maximum security and sustainability. Work strictly according to a program, without the option of changing it in the future. The contract execution logic on the blockchain is calculated by millions of computers around the world.</p>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={3}>
                    <div className="hfCard">
                        <div className="image__div">
                          <img src={require("../assets/images/secicon2_08.png")} alt="" className="img-fluid image__inside" />
                        </div>
                        
                        <h2 className="h1__feature">100% Transparency and Anonymity</h2>
                        <p className="p__feature">All payments are going directly to the members. Every transactions will be verifiable via blockchain. As a result, you can see how the business is progressing. When you sign up, no personal information is collected. No KYC information has ever been collected. Business and company are yours to manage and operate privately.</p>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={3}>
                    <div className="hfCard">
                      <div className="image__div">
                          <img src={require("../assets/images/secincon3_10.png")} alt="" className="img-fluid image__inside" />
                      </div>
                      
                      <h2 className="h1__feature">100%<br />No Boundaries</h2>
                      <p className="p__feature">You can do business from anywhere in the world. There are no geographical or population restrictions on how many people you can reach. Only your own efforts and marketing activities determine your results. Your participation in marketing will never be liquidated or terminated; it will be documented forever. This is what ToSolid has created especially for you!</p>
                  </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={3}>
                    <div className="hfCard">
                        <div className="image__div">
                           <img src={require("../assets/images/secicon4_12.png")} alt="" className="img-fluid image__inside" />
                        </div>
                        
                        <h2 className="h1__feature">100% Own and Manage Yourself</h2>
                        <p className="p__feature">The control over your assets is always in your hands. The smart contract eliminates the need for intermediaries. Automatically, all of your results are stored in  your own wallet that you control. You don't have to rely on the market situation; you get the asset itself, not just a price-based exchange rate difference.</p>
                    </div>
                  </GridItem>
                </GridContainer>
            </div>
          </div>
        </section>
        <section className="homeEarnings" name="earnings">
          <div className="container">
            <div className="titleDoubleColor">
                <h2>Earning <span>Plan</span></h2>
                <p className="earningTitleDescription">All data is stored on the blockchain in the public domain and can be verified!</p>
                <div className="earning__section">
                  <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className="text-center">
                        <img src={require("../assets/images/learnimg1_22.png")} alt="" className="img-fluid" />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className="text-center">
                        <img src={require("../assets/images/learnimg2_22.png")} alt="" className="img-fluid" />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className="text-center">
                        <img src={require("../assets/images/learnimg3_22.png")} alt="" className="img-fluid" />
                    </div>
                  </GridItem>
                </GridContainer>
                </div>
                <div className="earning__main">
                <div className="buttom__erning">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="earning__img">
                        <img src={require("../assets/images/walli1_26.png")} alt="" className="img-fluid" />
                        <p>This position is paid directly to you.</p>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="earning__img">
                        <img src={require("../assets/images/uparrow_26.png")} alt="" className="img-fluid" />
                        <p>This position pay your re-cycle fee, and move you into your sponsor matrix again.<br />Please note: All direct referrals always follow you.</p>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="earning__img">
                        <img src={require("../assets/images/reload_26.png")} alt="" className="img-fluid" />
                        <p>This is a qualification position and is passed upline to the first active sponsor above you.</p>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className="earning__img">
                        <img src={require("../assets/images/wall2_26.png")} alt="" className="img-fluid" />
                        <p>A part of this position is paid directly to you.</p>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
                </div>
                <div className="content">
                 <div className="earn__contnt">
                        <h2>Earn 15 levels deep !</h2>
                        <p>Build a massive organization and be rewarded for your efforts, instantly and directly. Leverage your time by working with others and earn daily.</p>
                    </div>
                </div>
            </div>
            </div>
        </section>
        <section className="top__erners">
          <div className="container">
            <div className="top__erners_icons">
              <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                  <div className="top__erners_img">
                    <p>Total Members</p>
                    <img src={require("../assets/images/topEarnersIcon1.png")} alt="" className="img-fluid" />
                    <h2>{detail.totalmember}</h2>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <div className="top__erners_img">
                    <p>New Members Today</p>
                    <img src={require("../assets/images/topEarnersIcon2.png")} alt="" className="img-fluid" />
                    <h2>{detail.todaymember}</h2>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <div className="top__erners_img">
                    <p>Total BUSD Earned</p>
                    <img src={require("../assets/images/topEarnersIcon3.png")} alt="" className="img-fluid" />
                    <h2>{detail.totalearn}</h2>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <div className="top__erners_img">
                    <p>Total Earned Today</p>
                    <img src={require("../assets/images/topEarnersIcon4.png")} alt="" className="img-fluid" />
                    <h2>{detail.todayearn.toFixed(5)}</h2>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
              <div className="tabelHomeTopEarners">
                <div className="titleDoubleColor">
                  <h2>Top 10 <span>Earners</span></h2>
                </div>
                <div className="table-responsive">
                   <table className="table homeTopTable">
                      <thead>
                         <tr>
                            <th>#</th>
                            <th>UserID</th>
                            <th>TxSilver</th>
                            <th>TxGold</th>
                            <th>TxPlatinum</th>
                         </tr>
                      </thead>
                      <tbody>
                      {
                      earnerList.map((item, i) => {
                          var id = parseInt(i)+1;
                          
                          var silver = parseFloat(item.silver);
                          var gold = parseFloat(item.gold);
                          var usdrate = parseFloat(rate);
                          var usdSilver = silver*usdrate;
                          var usdGold =gold*usdrate;
                        return (
                            <tr>
                                <td>{id}.</td>
                                <td>{item.receiverid}</td>
                                <td>{item.silver} BUSD<span>=${usdSilver.toFixed(5)}</span></td>
                                <td>{item.gold} BUSD<span>=${usdGold.toFixed(5)}</span></td>
                                <td>-<span>-</span></td>
                            </tr>
                          )
                        })
                      }
                      </tbody>
                   </table>
                </div>
                <div class="join__btn">
                <Button><Link to="/register">Join now</Link></Button>
                </div>
              </div>
            </div>
          </section >
          <section className="faq_sec " name="faq">
            <div className="container">
            <div class="titleDoubleColor"><h2>FAQ <span></span></h2></div>
  <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">

   
    <div class="card">

    
      <div class="card-header" role="tab" id="headingOne1">
        <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true"
           aria-controls="collapseOne1">
          <h5 class="mb-0">
          1.What is ToSolid?<i class="fas fa-angle-down rotate-icon"></i>
          </h5>
        </a>
      </div>


      <div id="collapseOne1" class="collapse show" role="tabpanel" aria-labelledby="headingOne1"
           data-parent="#accordionEx">
        <div class="card-body">
        <ul>
          <li>ToSolid is a global decentralized community with direct reward distribution established on Binance Smart Chain Blockchain smart contracts, and is completely decentralized, transparent, and secure.</li>
          <li>ToSolid is a completely decentralized marketing mechanism, it cannot be shut down or stopped by anyone, or altered in any way since it is not controlled by a central authority.</li>
          <li>Because of the power of blockchain technology, smart contracts cannot be changed or closed. Decentralization also brings full transparency, as all transactions are public and can be viewed online via a blockchain explorer.</li>
          <li>Because the ToSolid code is merely an algorithm that distributes rewards to the partners in a matrix structure, it does not store any funds. While adhering to specific conditions, it is a self-executing software algorithm that distributes partnership rewards between members of the platform.</li>
          <li>The following links will provide you with information about transactions:
            <ul>
              <li>TxSilver and TxGold: contract address</li>
              <li>TxPlatinum: coming soon</li>
            </ul>
          </li>
        </ul>
        </div>
      </div>

    </div>


    
    <div class="card">

    
      <div class="card-header" role="tab" id="headingTwo2">
        <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2"
           aria-expanded="false" aria-controls="collapseTwo2">
          <h5 class="mb-0">
          2.What is the advantages of ToSolid over trading?<i class="fas fa-angle-down rotate-icon"></i>
          </h5>
        </a>
      </div>

     
      <div id="collapseTwo2" class="collapse" role="tabpanel" aria-labelledby="headingTwo2"
           data-parent="#accordionEx">
        <div class="card-body">
        <ul>
          <li>
          Owning the asset means that market conditions are not a factor in your decision-making. Exchange rates do not have to be determined by price fluctuations.
          </li>
          <li>
          You will never be liquidated or have your positions expire, as your participation in the marketing process is recorded for all time.
            </li>
            <li>
            ToSolid's potential results are limitless, and they are solely dependent on your own marketing efforts and activities.
            </li>
            <li>
            ToSolid never stores or manages your funds, so there are no intermediaries.
            </li>
            <li>
            ToSolid is a smart-contract, not an intermediary. Automatically, all of your results are stored in your own wallet, which you control and manage on your own.
            </li>
            <li>It is possible to start small and grow exponentially, and entry is both affordable and inexpensive, making it accessible to everyone without any special qualifications.</li>
        </ul>
        </div>
      </div>

    </div>
    

    
    <div class="card">


      <div class="card-header" role="tab" id="headingThree3">
        <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree3"
           aria-expanded="false" aria-controls="collapseThree3">
          <h5 class="mb-0">
          3.How ToSolid Work?<i class="fas fa-angle-down rotate-icon"></i>
          </h5>
        </a>
      </div>

    
      <div id="collapseThree3" class="collapse" role="tabpanel" aria-labelledby="headingThree3"
           data-parent="#accordionEx">
        <div class="card-body">
        <ul>
          <li>
          ToSolid partners align vertically based on referral connections that track who invited whom. Plus, there are three matrix plans that can be upgraded for higher rewards and developed horizontally. And there are some interesting mechanics that allow overtakes and spillovers from your non-direct partners. 
          </li>
          <li>
          ToSolid has three different matrix plans: TxSilver, TxGold, and TxPlatinum, each with a different logic for how invited partners are arranged in the structure and how rewards are processed.
          </li>
          <li>
          TxSilver, TxGold and TxPlatinum have 12 levels in BUSD, with every upgrade costing twice as much as the one before it, respectively. However, the levels in each matrix must be activated one after the other.
          </li>
          <li>
          Joining ToSolid, a new member must send 10 BUSD to the smart-contract and activate the first levels in TxSilver and TxGold. The transaction itself serves as the platform's registration.
          </li>
        </ul>
        </div>
      </div>

    </div>
    <div class="card">


      <div class="card-header" role="tab" id="heading4">
        <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapse4"
           aria-expanded="false" aria-controls="collapse4">
          <h5 class="mb-0">
          4.What makes this different from a pyramid system?<i class="fas fa-angle-down rotate-icon"></i>
          </h5>
        </a>
      </div>

    
      <div id="collapse4" class="collapse" role="tabpanel" aria-labelledby="headingThree4"
           data-parent="#accordionEx">
        <div class="card-body">
       <ul>
         <li>
         Because it makes no unfulfilled promises to its members. The success of each individual is dependent on himself/herself. ToSolid will remain stable even if its size is multiplied a thousand times. ToSolid incurs no debts or other obligations.
         </li>
         <li>
         Both leader and newcomers to the ToSolid platform share the same conditions and opportunities. The smart contract, which cannot be deleted or altered, ensures that the platform's functionality. As long as there is electricity and a blockchain present, even if the website ceases to function, all the data and the entire structure will remain intact, as will the smart contract.
         </li>
         
       </ul>
        </div>
      </div>

    </div>
    <div class="card">


      <div class="card-header" role="tab" id="headingfive5">
        <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapse5"
           aria-expanded="false" aria-controls="collapse5">
          <h5 class="mb-0">
          5.Is the ToSolid platform safe?<i class="fas fa-angle-down rotate-icon"></i>
          </h5>
        </a>
      </div>

    
      <div id="collapse5" class="collapse" role="tabpanel" aria-labelledby="headingfive5"
           data-parent="#accordionEx">
        <div class="card-body">
       <ul>
         <li>
         ToSolid is a transparent, secure and dependable global decentralized community built on the BSC blockchain smart contract.
         </li>
         <li>
         ToSolid members can invite new partners via a personal link and earn 100% of the rewards in BUSD.
         </li>
         <li>
         ToSolid's smart contract doesn't store any funds, it only transfers rewards within the partner structure
         </li>
         <li>
         Personal wallets are credited immediately with rewards from ToSolid partners. There are no waiting periods.
         </li>
         <li>
         Earning rewards is unlimited. The results are solely dependent on the members' own actions and Activity.
         </li>
         <li>
         It does not have an owner or an administrator. A self-operating system, located on the BSC blockchain, it cannot be changed or altered.
         </li>
         
       </ul>
        </div>
      </div>

    </div>
 

  </div>
 
            </div>

          </section>


          <section className="social_big">
          <div class="titleDoubleColor"><h2>ToSolid  <span>Channels</span></h2></div>
            <div className="container">
              <div className="icons_social">
                <a href="https://www.facebook.com/profile.php?id=100074034229044" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/tosolid.pro/" target="_blank">
                  <i class="fab fa-instagram"></i>
                </a>
                <a href="https://t.me/tosolidpro" target="_blank">
                  <i class="fab fa-telegram"></i>
                </a>
              </div>
            </div>
          </section>
      <FooterHome />
    </div>
  );
}
