/*eslint-disable*/
import React,{ useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button} from "@material-ui/core";
import config from "../../config/config";
import { toastAlert } from "../../helper/toastAlert";
import axios from "axios";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.js";

const fullHost = config.fullHost;
const contractAddress = config.contractAddress;
const baseUrl = config.baseUrl;

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  
  const [smartContracturl, setsmartContracturl] = React.useState("");
  const [contractAddr, setcontractAddress] = React.useState(contractAddress);
  const [subscribemail, setsubscribemail] = React.useState("");

  useEffect(() => {
    Details();
  }, []);

  async function Details(){
    var url = fullHost+contractAddress
    setsmartContracturl(url);
  }

  async function SubmitNewletter(){

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var validEmail= re.test(String(subscribemail).toLowerCase());
    
    if(subscribemail==""){
      toastAlert('error', "Please enter email id", 'reg');
      return;
    }else if(!validEmail){
      toastAlert('error', "Please enter valid email id", 'reg');
      return;
    }else{
      
      var resp = await axios.post(baseUrl + 'subscribe-request', {email:subscribemail});
      var status = (resp && resp.data && resp.data.status)?resp.data.status:"error";
      if(status=="true"){
        toastAlert('success', "Successfully subscribed", 'reg');
        setsubscribemail("");
        return;
      }else if(status=="false"){
        toastAlert('error', "Email id already exits", 'reg');
        return;
      }
      
    }
      
  }

  return (
    <footer className={footerClasses + " footer_home"}>
      <div className={classes.container}>
        <div className="footer_bottom_home footer_grid">
          {/* <div className="text-left">
            <h2>© SovranoCoin</h2>
            <p>Smart-Contract Address: </p>
            <a href="#" className="ft_contract">0x5acc84a3e955Bdd76467d3348077d003f00fFB97</a>
            <div className="copyright_txt_inner m-0">
              Copyright {" "}&copy;{" "}{1900 + new Date().getYear()}{" "}
              <Link to="/" className={aClasses}>Sovranocoin,</Link> All Rights Reserved
            </div>
          </div> */}
          {/* <div className="footer_social_links">
            <ul>
              <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
              <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
              <li><a href="#" target="_blank"><i class="fab fa-telegram-plane"></i></a></li>
            </ul>
          </div> */}

          <div className="footer_new">
            {/* <h5>Subscribe for Free Newsletter</h5>
            <div className="subscripe_footer">
              <input value={subscribemail} onChange={e => setsubscribemail(e.target.value)} type="text" placeholder="enter your email" />
              <Button onClick={() =>{ SubmitNewletter()}} className="submitbutton">Submit</Button>
            </div> */}
            <h5>Smart Contract Address</h5>
            <p className="new_address_footer">{contractAddr}<span><a href={smartContracturl} target="_blank"><i class="fas fa-external-link-alt"></i></a></span></p>
              <span className="copyrifht_text">Copyright © 2021 ToSolid. All Rights Reserved</span>
          </div>
        </div>
        <p className="footer_a"><a href="/disclaimers">Disclaimers</a></p>
      </div>


    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
