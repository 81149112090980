let key = {};
if (process.env.NODE_ENV === "production") {
	console.log("Set Production Config");
	key = {
		frontUrl: "https://tosolid-frontend.pages.dev/",
		baseUrl: "https://tosolidapi.wearedev.team/api/",
		secretKey: "asd4c61fvb912",
		fullHost: "https://testnet.bscscan.com/address/",
		contractAddress: "0xbc82630418D8174e265d2b736b13080299092251",
		tokenAddress: "0x535C526f29C23aC1196B8f91252f81954EaeBc42",
		decimals: 1000000000000000000,
		mainnet: false,
		networkVersion: 97,
		networkName: "Bscan Network"
	};

} else {
	console.log("Set Development Config");

	key = {
		frontUrl: "https://tosolid-frontend.pages.dev/",
		baseUrl: "https://tosolidapi.wearedev.team/api/",
		secretKey: "asd4c61fvb912",
		fullHost: "https://testnet.bscscan.com/address/",
		contractAddress: "0xbc82630418D8174e265d2b736b13080299092251",
		tokenAddress: "0x535C526f29C23aC1196B8f91252f81954EaeBc42",
		decimals: 1000000000000000000,
		mainnet: false,
		networkVersion: 97,
		networkName: "Bscan Network"
	};
}

export default key;


