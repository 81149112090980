/*eslint-disable*/
import React, { useEffect, useState } from "react";
// react components for routing our app without refresh
import { Link } from "react-scroll";
import { NavLink,useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import $ from 'jquery';
// @material-ui/icons
import { Help, LockOpen, Assignment } from "@material-ui/icons";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Button } from "@material-ui/core";
import config from "../../config/config";
import { connection } from "../../helper/connection";

const useStyles = makeStyles(styles);
const networkVersion = config.networkVersion;
const networkName = config.networkName;

export default function HeaderLinks(props) {

  const classes = useStyles();
  const [isNetwork, setisNetwork]= useState(false);

  const location = useLocation();
 // console.log('locationlocation',location.pathname)
  try{
    window.ethereum.on('networkChanged', function (networkId) {
      console.log('networkIdnetworkId',networkId)
      checkNetwork();
    })
  }catch(err){

  }
  

  useEffect(() => {
    setTimeout(function () {
        checkNetwork();
    }, 700);
  }, []);

  async function checkNetwork(){

      var WEB3 = await connection();
      var currAddr = WEB3.address;
      var network = WEB3.network;
      if(networkVersion==network){
        setisNetwork(true);
        $('#loader_modal').css('display','none');
        $('#loader_modal').removeClass('show');
      }else{
        $('#loader_modal').css('display','block');
        $('#loader_modal').addClass('show');
        setisNetwork(false)
      }

  }

  return (
    <div className="home_page_menu">


    {/* <div class="modal fade primary_modal" id="loader_modal" tabindex="-1" role="dialog" aria-labelledby="loader_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <p style={{"textAlign":"center","fontSize":"20px"}}>Wrong Network</p>
            </div>
            <div class="modal-body text-center">     
              <p className="mb-0" style={{"fontSize":"16"}}>Please login into {networkName}</p>                       
              <img src={require("../../assets/images/loader.gif")} alt="Loader" className="img-fluid" style={{width: "200px"}} />
            </div>
          </div>
        </div>
      </div> */}

      {isNetwork &&
      <List className={classes.list + " main_navbar"}>
          {location && location.pathname !="/login" && location.pathname !="/register" &&
            <ListItem className={classes.listItem + " logout_btn"}>
              <Link to="features" spy={true} smooth={true} duration={250} className="nav-link">Feature</Link>
            </ListItem>
          }
          {location && location.pathname !="/login" && location.pathname !="/register" &&
            <ListItem className={classes.listItem + " logout_btn"}>
              <Link to="earnings" spy={true} smooth={true} duration={250} color="transparent" className="nav-link">Earning Plan</Link>
            </ListItem>
          }
          {location && location.pathname !="/login" && location.pathname !="/register" &&
          <ListItem className={classes.listItem + " logout_btn"}>
            <Link to="faq" spy={true} smooth={true} duration={250} color="transparent" className="nav-link">Faq</Link>
          </ListItem>
          }
          <ListItem className={classes.listItem + " logout_btn"}>
            <NavLink to="/login" color="transparent"><Button className="topLinkButton"><span className="innerButtonBg">Login</span></Button></NavLink>
          </ListItem>
          <ListItem className={classes.listItem + " logout_btn"}>
            <NavLink to="/register" color="transparent"><Button className="topLinkButton"><span className="innerButtonBg">Register</span></Button></NavLink>
          </ListItem>
        </List>
      }
      {!isNetwork &&
      <List className={classes.list + " main_navbar"}>
        <ListItem className={classes.listItem + " logout_btn"}>
          <Link  spy={true} smooth={true} duration={250} className="nav-link">Please login into {networkName}</Link>
        </ListItem>
      </List>
      }
    </div>
  );
}
